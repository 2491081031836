import { IOrigin } from "@/stores/mixCutDetailStore/type";
import useMaterialPlayInfo from "./useMaterialPlayInfo";
import mixCutDetailStore from "@/stores/mixCutDetailStore";
import useMixCutDetailInit from "./useMixCutDetailInit";
import usePreviewConfig from "../preview/usePreview";
import usePreviewInfo from "./usePreviewInfo";
import { v4 as uuid } from "uuid";
import { duration } from "moment";
import useMixCutDetailSave from "./useMixCutDetailSave";
// import previewConfing from "@/stores/previewStore";
import ConfigStore from "@/stores/mixedConfig/index";
export interface IUseJsonToRenderObj {
  handletoOriginJsonRender: (data: IOrigin) => void;
}

const useJsonToRenderObj = (): IUseJsonToRenderObj => {
  const { toInitRenderDataSetPreviewData } = usePreviewConfig();
  const { initTransitionGroup, initGlobleCaptionGroup } = useMixCutDetailInit();
  const { handleSaveMixCutDetailSave } = useMixCutDetailSave();
  const { toIdsSetPlayInfo } = useMaterialPlayInfo();
  const { calcPreviewInfo } = usePreviewInfo();
  const handletoOriginJsonRender = (data: IOrigin) => {
    const materials = data.scenes.map(item => item.materials.map(li => li.id)).flat()
    const _materials = {}
    materials.forEach((item) => {
      _materials[item] = {
        url: "",
        cover: "",
      };
    })
    mixCutDetailStore.setProjectMaterialsInfo(_materials);
    ConfigStore.setMaterials(data._materials || null);
    // mixCutDetailStore.setVersion(data.version || null);
    ConfigStore.setVersion(data.version || null);
    const newMaterialInfoKeys = Object.keys(data._materials || {});
    toIdsSetPlayInfo(newMaterialInfoKeys);
    // mixCutDetailStore.setProjectSences(
    //   data.scenes.map((item) => {
    //     return {
    //       id: item.id || uuid(),
    //       name: item.name,
    //       setting: item.setting,
    //       materials: item.materials,
    //     };
    //   }),
    // );
    ConfigStore.setScene(
      data.scenes.map((item) => {
        return {
          id: item.id || uuid(),
          name: item.name,
          setting: item.setting,
          materials: item.materials,
          caption: item.caption
        };
      }),
    );
    if (data?.global_settings?.enabled) {
      mixCutDetailStore.setProTitle(data?.global_settings?.titles);
      ConfigStore.global_settings.titles = data?.global_settings?.titles;
    } else {
      const titles = data?.scenes.map((item) => item.title);
      mixCutDetailStore.setProTitle(titles);
      ConfigStore.global_settings.titles = titles;
    }
    mixCutDetailStore.setProjectCover(
      (data?.global_settings?.cover?.images || []).map((item) => item),
    );
    ConfigStore.global_settings.cover = (data?.global_settings?.cover?.images || []).map((item) => item)
    mixCutDetailStore.setProjectBgAudio(
      data?.global_settings?.background?.audio,
    );
    ConfigStore.global_settings.background.audio = data?.global_settings?.background?.audio
    mixCutDetailStore.setProjectBgColor(
      data?.global_settings?.background?.color,
    );
    ConfigStore.global_settings.background.color = data?.global_settings?.background?.color
    mixCutDetailStore.setProjectBgImages(
      data?.global_settings?.background?.images,
    );
    ConfigStore.global_settings.background.images = data?.global_settings?.background?.images
    mixCutDetailStore.setProjectBgAudioVolum(
      data?.global_settings?.background?.audio_volume || 50,
    );
    ConfigStore.global_settings.background.audio_volume = data?.global_settings?.background?.audio_volume || 50
    if (data.global_settings.enabled) {
      initTransitionGroup();
      mixCutDetailStore.setProjectIsGolbal(true);
      ConfigStore.global_settings.enabled = true;
      mixCutDetailStore.setProjectGlobalCaptions(data.global_settings.captions);
      ConfigStore.global_settings.captions = data.global_settings.captions;
      mixCutDetailStore.setProjectGlobalTransition({
        duration: data.global_settings.transition.duration,
        effects: data.global_settings.transition.effects,
      });
      ConfigStore.global_settings.transition = {
        duration: data.global_settings.transition.duration,
        effects: data.global_settings.transition.effects,
      };
    } else {
      initGlobleCaptionGroup();
      mixCutDetailStore.setProjectIsGolbal(false);
      ConfigStore.global_settings.enabled = false;
      mixCutDetailStore.setProjectCaption(
        data.scenes.map((item) => {
          return item.caption;
        }),
      );
      mixCutDetailStore.setTransition(data.transition);
      ConfigStore.transition = data.transition;
    }
    if (!mixCutDetailStore.getVerison()) {
      initFontBgColor();
      handleSaveMixCutDetailSave();
    }
    if (!ConfigStore.version) {
      initFontBgColor();
      handleSaveMixCutDetailSave();
    }
    toInitRenderDataSetPreviewData(data);
    calcPreviewInfo(data);
  };

  const initFontBgColor = () => {
    if (!ConfigStore.global_settings.enabled) {
      // const captions = mixCutDetailStore.getProjectCaption();
      const captions = ConfigStore.scenes.map((item) => item.caption)
      captions.forEach((item) => {
        item.forEach((caption) => {
          const bgColor = caption.text_setting.background_color;
          if (bgColor === "#000000" || bgColor === "black") {
            caption.text_setting.background_color = "";
          }
        });
      });
      const titles = mixCutDetailStore.getProjectTitle();
      titles.forEach((item) => {
        item.content.forEach((title) => {
          const bgColor = title.text_setting.background_color;
          if (bgColor === "#000000" || bgColor === "black") {
            title.text_setting.background_color = "";
          }
        });
      });
    }
    const gCaptions = mixCutDetailStore.getProjectGlobalCaptions();
    gCaptions.forEach((gCaption) => {
      const bgColor = gCaption.text_setting.background_color;
      if (bgColor === "#000000" || bgColor === "black") {
        gCaption.text_setting.background_color = "";
      }
    });
    ConfigStore.setVersion("1.0.0");
  };

  return {
    handletoOriginJsonRender,
  };
};

export default useJsonToRenderObj;
