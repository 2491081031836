import mixCutDetailStore from "@/stores/mixCutDetailStore";
import { initCaptionItem } from "@/stores/mixCutDetailStore/init";
import {
  GlobalCaption,
  ICaption,
} from "@/stores/mixCutDetailStore/type/caption";
import useMixCutDetailSave from "./useMixCutDetailSave";
import { Message } from "@arco-design/web-react";
import MixedConfig from "@/stores/mixedConfig";

export interface IUseGlobalCaptionReturn {
  globalCaptions: GlobalCaption;
  setGlobalCaptions: (e: GlobalCaption) => void;
  handleAddGlobalCaptions: () => void;
  handleDeleteGlobalCaptionItem: (index: number) => void;
  handleGlobalCationsChange: (index: number, data: ICaption) => void;
}

const useGlobalCaption = (): IUseGlobalCaptionReturn => {
  const { handleSaveMixCutDetailSave } = useMixCutDetailSave();
  const handleGlobalCationsChange = (index: number, data: ICaption) => {
    const newGCaption = [...mixCutDetailStore.getProjectGlobalCaptions()];
    newGCaption.splice(index, 1, data);
    if (data?.text_setting?.is_global) {
      const { x, y } = data.text_setting;
      newGCaption.forEach((item) => {
        item.text_setting.x = x;
        item.text_setting.y = y;
      });
    }
    mixCutDetailStore.setProjectGlobalCaptions([...newGCaption]);
    MixedConfig.global_settings.captions = newGCaption;
    handleSaveMixCutDetailSave();
  };

  const handleAddGlobalCaptions = () => {
    const newItem: ICaption = {
      id: Math.floor(Date.now() + Math.random() * 10000),
      content: "",
      _content_json: "",
      ...initCaptionItem,
    };
    const gCaptions = mixCutDetailStore.getProjectGlobalCaptions();
    mixCutDetailStore.setProjectGlobalCaptions([...gCaptions, newItem]);
    MixedConfig.global_settings.captions = [...gCaptions, newItem];
    handleSaveMixCutDetailSave();
  };

  const handleDeleteGlobalCaptionItem = (index: number) => {
    if (mixCutDetailStore.getProjectGlobalCaptions().length === 1) {
      Message.error("必须保证有一个台词组，无法删除！");
      return;
    }
    const gCaptions = [...mixCutDetailStore.getProjectGlobalCaptions()];
    gCaptions.splice(index, 1);
    mixCutDetailStore.setProjectGlobalCaptions(gCaptions);
    MixedConfig.global_settings.captions = gCaptions;
    handleSaveMixCutDetailSave();
  };

  return {
    globalCaptions: mixCutDetailStore.getProjectGlobalCaptions(),
    setGlobalCaptions: mixCutDetailStore.setProjectGlobalCaptions,
    handleAddGlobalCaptions,
    handleDeleteGlobalCaptionItem,
    handleGlobalCationsChange,
  };
};

export default useGlobalCaption;
